/*
 * @Author: mayefeng
 * @Date: 2023-01-06 16:59:05
 * @LastEditors: sfy sam2020888@outlook.com
 * @LastEditTime: 2023-09-01 10:12:36
 * @FilePath: /boulderbi/src/index.tsx
 * @Description: update here
 */
import './public-path';
import 'core-js/actual';
// import 'regenerator-runtime/runtime';
// import 'antd-mobile/es/global';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import '@bui/iconfont';
import '@bui/eimos-sdk/dist/sdk';
import '@bui/req';
import { isQiankun } from './config';
import './infra/eimos';
import './infra/report';
import App from './App';
import './global.less';
import { eventBus } from './utils/events';
import { AppIsolate } from '@/infra/appIsolate';

// if (!location.href.includes('appstudio')) {
// }

const whitelist = [
  // 活动任务-活动库
  /202402291428043011051/,
  // 业务对象
  /202403061408519000402/,

  // 业务对象-业务规则库
  // /\/pro\/boulderBI\/release\/202402291428043011051/,
  //流程场景
  /202403040919055727153/,
  /202403061058545868568/,
  //已经编排的场景
  /20240320105845972409/,
  /S202403041019022805480_Task20240304101805263188601/,
  /\/lcp\/vm\/page_model_view/,
  /\/lcp\/vm\/service_market/,
  /\/vm\/app_pack/,
  /platform-maker/,
];
new AppIsolate({
  whitelist,
  isolateCode: 'appCode',
}).init();

const whitelist2 = [
  // 活动任务-活动库
  /202402291428043011051/,
  // 业务对象
  /202403061408519000402/,

  // 业务对象-业务规则库
  // /\/pro\/boulderBI\/release\/202402291428043011051/,
  //流程场景
  /202403040919055727153/,
  /202403061058545868568/,
  //已经编排的场景
  /20240320105845972409/,
  /S202403041019022805480_Task20240304101805263188601/,
  /\/lcp\/vm\/page_model_view/,
  /\/lcp\/vm\/service_market/,
  /\/vm\/app_pack/,
];

new AppIsolate({
  whitelist: whitelist2,
  isolateCode: 'filterProcessId',
}).init();

// const whitelist = [
//   // 新增场景
//   /\/pro\//,
// ];

// new AppIsolate({
//   whitelist,
//   isolateCode: 'appCode',
// }).init();

const render = (props: Record<any, any> = {}) => {
  const { container } = props;
  const target = document.querySelector('#EIMOS-APP');

  let render = (
    <Suspense fallback>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  );
  if (container) {
    render = (
      <Suspense fallback>
        <MemoryRouter>
          <App {...props} />
        </MemoryRouter>
      </Suspense>
    );
  }

  ReactDOM.render(render, container ? container.querySelector('#EIMOS-APP') : target);
};

if (!isQiankun) {
  render();
}
export async function bootstrap() {
  //
}

let initProps: Record<any, any> = {};
export async function mount(props: Record<any, any>) {
  initProps = props;
  render(props);
}
export async function unmount(props: any) {
  const container = props.container || document;
  const target = container.querySelector('#EIMOS-APP');
  eventBus.removeAllListeners();

  if (target) {
    ReactDOM.unmountComponentAtNode(target);
    console.log('eimos 页面卸载成功');
  }
}

export async function update(props: Record<any, any>) {
  if (props.type === 'REFRESH_LIST') {
    eventBus.emit('REFRESH');
    return;
  }
  if (props.type === 'FILTER_LINKAGE') {
    eventBus.emit('FILTER_LINKAGE', { qiankunFilter: props.filter });
    return;
  }
  render({
    ...initProps,
    pageConfig: {
      route: props?.fullPath,
    },
  });
}
