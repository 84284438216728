import { useSystemStore } from '@/hooks/useSystemConfig';
import { usePermission } from '@bui/eimos-sdk';
import { useEffect, useState } from 'react';
import { useAppCode } from './useAppCode';
import { usePreferenceStore } from '@/hooks/usePreferenceConfig';
import { log } from '@/infra';
import { Apis, buiService } from '@bui/req';
import { isQiankun } from '@/config';
import { useMonitorStore } from './useMonitor';
import { prefetch } from '@/infra/prefetch';

/**
 * app 相关初始化请求放到这里，并发请求
 * @returns
 */
export const useAppInit = () => {
  const permission = usePermission();
  const appCode = useAppCode();
  const [state, setState] = useState({ inited: false, error: false });
  const sysStore = useSystemStore((state) => {
    return {
      init: state.init,
    };
  });
  const preferStore = usePreferenceStore((state) => {
    return {
      init: state.init,
    };
  });
  useEffect(() => {
    let unmount = () => {
      //
    };
    const effect = async () => {
      console.timeLog('appInit');
      const init = async () => {
        // const systemLang = localStorage.getItem('eimos:lang');
        const preference = await preferStore.init();
        const res = await Apis.request({
          url: '/api/gateway/system/sysAndUser/info',
          method: 'get',
          cache: true,
        });
        prefetch.start();
        log.info('user init info', res);
        storageSystemTimeConfig({
          timeZone: res?.userPersonalizeConfig?.timeZone?.code,
          timeFormat: res?.userPersonalizeConfig?.timeFormat?.code,
          dateFormat: res?.userPersonalizeConfig?.dateFormat?.code,
        });
        const [perm, system, userInfo] = await Promise.all([
          permission.init(appCode),
          sysStore.init(),
          buiService.userInfo({
            error: false,
          }),
        ]);
        console.log('perm', perm);
        return [perm, system, preference, userInfo] as const;
      };
      try {
        const [, , , userInfo] = await init();
        if (!isQiankun) {
          try {
            const monitorConfig = useSystemStore.getState().getMonitorConfig();
            if (monitorConfig.enabled) {
              await useMonitorStore.getState().init({
                // dsn: 'http://821a2ce4b53847669a0417bde368f1ec@172.16.5.45:9000/2',
                tracesSampleRate: 1.0,
                ...monitorConfig.config,
                type: monitorConfig.type,
                debug: true,
                enabled: monitorConfig.enabled,
                user: {
                  username: userInfo.user.userName!,
                  id: userInfo.user.id!,
                },
              });
              useMonitorStore.getState().start();
              useMonitorStore.getState().info('Page View', {
                tags: {},
              });
              const onpopstate = () => {
                console.log('pv 统计');
                useMonitorStore.getState().info('Page View', {});
              };
              window.addEventListener('popstate', onpopstate);
              unmount = () => {
                window.removeEventListener('popstate', onpopstate);
              };
              console.log('monitor 初始化 完成');
            }
          } catch (e) {
            console.error('monitor 初始化失败', e);
          }
        }
        console.timeEnd('appInit');

        setState((pre) => {
          return {
            ...pre,
            inited: true,
          };
        });
      } catch (e) {
        log.error('appInit', '应用初始化报错', e);
        // 不要立即展示错误，有可能是 token 过期需要跳转
        setTimeout(() => {
          setState({
            inited: true,
            error: true,
          });
        }, 1500);
      }
    };
    effect();
    return () => {
      unmount();
    };
  }, []);
  return state;
};

const storageSystemTimeConfig = (config: {
  timeZone: string;
  timeFormat: string;
  dateFormat: string;
}) => {
  localStorage.setItem('eimos:timeFormat', config.timeFormat);
  localStorage.setItem('eimos:dateFormat', config.dateFormat);

  if (config.timeZone == 'FOLLOW_LOCAL') {
    // 获取当前UTC格式的系统时区
    const code = getUTCOffset();
    localStorage.setItem('eimos:timeZone', code);
    return;
  }
  localStorage.setItem('eimos:timeZone', config.timeZone);
};

function getUTCOffset() {
  // 创建一个新的Date对象，表示当前时间
  const now = new Date();

  // 获取当前时间的UTC偏移量，以分钟为单位
  const offsetInMinutes = now.getTimezoneOffset();

  // 将偏移量转换为小时和分钟
  const offsetInHours = Math.floor(Math.abs(offsetInMinutes) / 60);
  const offsetInMinutesPart = Math.abs(offsetInMinutes) % 60;

  // 构建UTC偏移量字符串
  const sign = offsetInMinutes < 0 ? '+' : '-';
  const utcOffsetString = `UTC${sign}${String(offsetInHours).padStart(2, '0')}:${String(
    offsetInMinutesPart,
  ).padStart(2, '0')}`;

  return utcOffsetString;
}
