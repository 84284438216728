import React, { useRef, useState } from 'react';
import { log } from './appIsolate/log';
import { useSystemStore } from '../hooks/useSystemConfig';
import type { ArgsProps } from 'antd/lib/notification';
import IconFont from '@bui/iconfont';
import { Button, Spin } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import styles from './proxy.module.less';
import cx from 'classnames';
import { useUnmountedRef } from 'ahooks';
import DraggableModal from '@bui/bui-modal';
import FlexDrawer from '@/components/FlexDrawer';
import Loading from '@/components/Loading';
import { usePreferenceStore } from '@/hooks/usePreferenceConfig';

import { usePaste } from '@/hooks/usePaste';
import { useMicroAppStore } from '@/hooks/useMicroAppStore';
import { uniqueId } from 'lodash';
import { isMobile } from '@/utils/isMobile';
import { useTranslate } from '@/hooks/useTranslate';

class NumCache {
  cacheMap = new Map<string, number>();
  getId() {
    const container = this.getContainer();
    return container.id || '';
  }
  getContainer() {
    const activePageContainer = useMicroAppStore.getState().getActivePageContainer();
    return activePageContainer;
  }
  add() {
    const id = this.getId();
    this.cacheMap.set(id, (this.cacheMap.get(id) || 0) + 1);
  }

  delete() {
    const id = this.getId();
    this.cacheMap.set(id, (this.cacheMap.get(id) || 0) - 1);
  }

  getNum() {
    const id = this.getId();
    return this.cacheMap.get(id) || 0;
  }
  clear() {
    const id = this.getId();
    this.cacheMap.set(id, 0);
  }
  clearAll() {
    this.cacheMap = new Map();
  }
}

const numCache = new NumCache();

const getPromiseLikeFunc = () => {
  const ret = function () {
    //
  };
  return Object.assign(ret, Promise.prototype);
};
const originNotificationOpen = window.antd.notification.open;
const originNotificationClose = window.antd.notification.close;
const originNotificationDestroy = window.antd.notification.destroy;
if (!(window.antd as any).originModal) {
  (window.antd as any).originModal = window.antd.Modal;
}

if (!(window.antd as any).originDrawer) {
  (window.antd as any).originDrawer = window.antd.Drawer;
}

const originModal = (window.antd as any).originModal || window.antd.Modal;
const originDrawer = (window.antd as any).originDrawer || window.antd.Drawer;
export class NotificationProxy {
  isProxy = false;
  proxy(props: NotificationProxyProps) {
    this.unproxy();
    const { autoCloseError } = props;
    window.antd.notification.config({
      top: 90,
      closeIcon: <IconFont type="icon-close" className={styles['close-icon']} />,
    });

    type NotificationType = 'success' | 'error' | 'warn' | 'warning' | 'info';
    const keySet = new Set<string>();
    const duration = autoCloseError ? 4 : 0;
    const rewriteConfig: Record<NotificationType, { duration?: number; icon: React.ReactNode }> = {
      success: {
        icon: <IconFont style={{ fontSize: 24 }} type="icon-sucess-face" />,
      },
      error: {
        icon: <IconFont style={{ fontSize: 24 }} type="icon-error-face" />,
        duration,
      },
      warn: {
        icon: <IconFont style={{ fontSize: 24 }} type="icon-warning-face" />,
        duration,
      },
      warning: {
        icon: <IconFont style={{ fontSize: 24 }} type="icon-warning-face" />,
        duration,
      },
      info: {
        icon: '',
      },
    };

    const rewriteOpen = () => {
      const origin = originNotificationOpen;
      return (config: ArgsProps & { type: NotificationType }) => {
        const defaultConfig: Partial<ArgsProps> = rewriteConfig[config.type];
        const type = config.type;
        const id = uniqueId();
        const activePageContainer = useMicroAppStore.getState().getActivePageContainer();
        const containerId = activePageContainer ? activePageContainer.id : '';
        if (!config.key || !keySet.has(config.key)) {
          numCache.add();
          props.onCountChange(numCache.getNum() || 0);
        }

        if (config.key && !keySet.has(config.key)) {
          keySet.add(config.key);
        }
        const onClose = () => {
          if (config.key && keySet.has(config.key)) {
            keySet.delete(config.key);
          }
          numCache.delete();
          props.onCountChange(numCache.getNum());
          config.onClose?.();
        };

        const onCopy = () => {
          props.onCopy(document.getElementById(id)?.innerText || (config.description as string));
        };
        const description =
          ['error', 'warning', 'warn'].includes(type) && config.description ? (
            <div>
              <span id={id} className={styles['description']}>
                {config.description}
              </span>
              <CopyBtn onCopy={onCopy} />
            </div>
          ) : (
            config.description
          );
        const duration = autoCloseError ? config.duration || 4 : 0;
        return origin.call(this, {
          ...defaultConfig,
          prefixCls: `${containerId} ant-notification`,
          getContainer() {
            return activePageContainer;
          },
          ...config,
          description,
          duration,
          onClose,
        });
      };
    };
    const rewriteClose = () => {
      const origin = originNotificationClose;
      return (key: string) => {
        if (key && keySet.has(key)) {
          keySet.delete(key);
          numCache.delete();
          props.onCountChange(numCache.getNum());
        }
        return origin.call(this, key);
      };
    };

    const rewriteDestroy = () => {
      const origin = originNotificationDestroy;
      return () => {
        numCache.clear();
        keySet.clear();
        props.onCountChange(numCache.getNum());
        return origin.call(this);
      };
    };

    window.antd.notification.open = rewriteOpen();
    window.antd.notification.close = rewriteClose();
    window.antd.notification.destroy = rewriteDestroy();
    console.log(window.antd.notification.info);
    if (!isMobile()) {
      window.antd.Modal = DraggableModal;
      window.antd.Drawer = FlexDrawer;
    }
    Spin.setDefaultIndicator(<Loading />);

    this._unproxyCallbacks.push(() => {
      numCache.clear();
      keySet.clear();
      window.antd.notification.open = originNotificationOpen;
      window.antd.notification.close = originNotificationClose;
      window.antd.notification.destroy = originNotificationDestroy;
      window.antd.Modal = originModal;
      window.antd.Drawer = originDrawer;
    });
  }

  private _unproxyCallbacks: Array<(...args: any[]) => any> = [];

  unproxy() {
    this._unproxyCallbacks.forEach((callback) => {
      callback();
    });
    this._unproxyCallbacks = [];
  }
}

const notificationProxy = new NotificationProxy();

export const useProxy = () => {
  /**
   * 根据用户配置重写 antd 的 message.error
   * 实现 demo 环境下不展示错误提示
   */
  const [onCopy] = usePaste({
    onPaste(value) {
      log.info('notification 复制', value);
    },
  });
  useSystemStore.subscribe((state) => {
    if (state.data?.hideErrorFlag) {
      log.warn('hideNotifyError', '页面中的错误提示将被隐藏');
      if (window.antd?.message) {
        const rewriteMessage = (type: string, callback: () => void) => {
          return (...args: any[]) => {
            callback?.();
            console.log(`%c⚠️ message.${type}:`, 'color: orange; font-weight: bold;', ...args);
            return getPromiseLikeFunc();
          };
        };
        window.antd.message.error = rewriteMessage('error', () => {
          log.warn('message', '#SPZN-18318 请改用 notification.error 方法');
        });
        window.antd.message.warning = rewriteMessage('warning', () => {
          log.warn('message', '#SPZN-18318 请改用 notification.warning 方法');
        });
        window.antd.message.warn = rewriteMessage('warn', () => {
          log.warn('message', '#SPZN-18318 请改用 notification.warn 方法');
        });
      }

      if (window.antd?.notification) {
        const rewriteMessage = (type: string) => {
          return (...args: any[]) => {
            console.log(`%c⚠️ notification.${type}:`, 'color: orange; font-weight: bold;', ...args);
            return getPromiseLikeFunc();
          };
        };
        window.antd.notification.warn = rewriteMessage('warn');
        window.antd.notification.warning = rewriteMessage('warning');
        window.antd.notification.error = rewriteMessage('error');
      }
    } else {
      /**
       * 提示开发者升级
       */
      const rewriteMessage = (type: 'error' | 'warning' | 'warn') => {
        const origin = window.antd.message[type];
        return (...args: any[]) => {
          log.warn('message', `#SPZN-18318 请改用 notification.${type} 方法`);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return origin.call(this, ...args);
        };
      };
      // 提示开发者升级
      window.antd.message.error = rewriteMessage('error');
      window.antd.message.warning = rewriteMessage('warning');
      window.antd.message.warn = rewriteMessage('warn');
    }
  });

  usePreferenceStore.subscribe((state) => {
    const flag = state.data?.autoCloseError === 'true';
    notificationProxy.proxy({
      onCopy(value) {
        onCopy(value);
      },
      onCountChange(count) {
        setTimeout(() => {
          const container = numCache.getContainer();
          if (container) {
            if (!container.querySelector('#eimos-close-all-notification')) {
              const tpl = document.createElement('template');
              tpl.innerHTML =
                '<span id="eimos-close-all-notification" class="ant-tag ant-tag-has-color App_close-all-notification__Di8YH" style="background-color: rgb(16, 142, 233); opacity: 1; visibility: visible;">全部关闭<span role="img" aria-label="close" tabindex="-1" class="anticon anticon-close ant-tag-close-icon"><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></span></span>';
              container.appendChild(tpl.content);
              const notificationCloseAllBtn = container.querySelector(
                '#eimos-close-all-notification',
              )! as any;
              notificationCloseAllBtn.addEventListener(
                'click',
                () => {
                  numCache.clearAll();
                  window.antd.notification.destroy();
                  document.querySelectorAll('#eimos-close-all-notification').forEach((dom: any) => {
                    dom.style.opacity = '0';
                    dom.style.visibility = 'hidden';
                  });
                },
                false,
              );
            }
          }
          const notificationCloseAllBtn = container.querySelector(
            '#eimos-close-all-notification',
          )! as any;
          if (notificationCloseAllBtn) {
            const shouldHide = count <= 1;
            if (shouldHide) {
              notificationCloseAllBtn.style.opacity = '0';
              notificationCloseAllBtn.style.visibility = 'hidden';
            } else {
              notificationCloseAllBtn.style.opacity = '1';
              notificationCloseAllBtn.style.visibility = 'visible';
            }
          } else {
            //   document.body.appenChild();
          }
          console.log(count);
        }, 0);
      },
      autoCloseError: flag,
    });
  });
};

type NotificationProxyProps = {
  onCountChange(count: number): void;
  onCopy(value: string): void;
  autoCloseError: boolean;
};

const CopyBtn = (props: { onCopy(): void }) => {
  const [done, setDone] = useState(false);
  const t = useTranslate();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const unmountedRef = useUnmountedRef();
  const onClick = () => {
    setDone(true);
    props.onCopy();
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      if (!unmountedRef.current) {
        setDone(false);
      }
    }, 2000);
  };
  return (
    <Button type="link" onClick={onClick} style={{ padding: '0 11px', height: 'auto' }}>
      {t('copy', '复制')}{' '}
      <CheckOutlined className={cx(styles['check'], done ? styles['copied'] : null)} />
    </Button>
  );
};
